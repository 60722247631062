<script>
import { RaiEmployeeAvatarList, VxDataListItem } from "@/core-ui";
import BuyNumber from "../../BuyNumber.vue";
import FlaggedName from "@/components/customers/FlaggedName";
import { isAfter, addMinutes, parseISO } from "date-fns";
import { capitalize } from "lodash";
import { injectClockedInEmployees } from "@/mixins/employee";
import VxChip from "@/core-ui/src/components/vx/VxChip/VxChip.vue";
import Stopwatch from "@/components/util/Stopwatch.vue";
import StartSortingButton from "../../StartSortingButton.vue";
import Avatar from "@/components/employees/Avatar.vue";
import { OPEN_BUY_STATUSES } from "../../../OpenBuyStatuses";

export default {
  name: "BuyListItemOpen",
  components: {
    VxDataListItem,
    BuyNumber,
    FlaggedName,
    RaiEmployeeAvatarList,
    VxChip,
    Stopwatch,
    StartSortingButton,
    Avatar,
  },
  mixins: [injectClockedInEmployees],
  interval: undefined,
  props: {
    buy: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    buysForSorting: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    now: new Date(),
  }),
  computed: {
    due() {
      return parseISO(this.buy.estimatedPickupAt);
    },
    showSortingBtn() {
      return this.buysForSorting.some((buy) => buy?.id === this.buy?.id);
    },
    hasSorter() {
      return !!this.buy.sorterId;
    },
    // Is due less than 5 minutes from now
    isDueInFive() {
      return isAfter(addMinutes(this.now, 5), this.due);
    },
    // Is due less than 15 minutes from now
    isDueSoon() {
      return isAfter(addMinutes(this.now, 15), this.due);
    },
    isWarning() {
      return !this.isError && this.isDueSoon;
    },
    isError() {
      return (
        isAfter(this.now, this.due) ||
        this.isDueInFive ||
        (!this.hasSorter && this.isDueSoon)
      );
    },
    dynamicClasses() {
      return {
        error: this.isError,
        warning: this.isWarning,
        "lighten-4": this.isWarning || this.isError,
      };
    },
    employeesToShow() {
      return this.clockedInEmployees.filter(
        (employee) =>
          employee.id === this.buy.sorterId ||
          employee.id === this.buy.checkedInByEmployeeId ||
          employee.id === this.buy.entererId
      );
    },
    mostRecentEmployee() {
      if (this.buy.entererId) return this.buy.entererId;
      if (this.buy.sorterId) return this.buy.sorterId;
      if (this.buy.checkedInByEmployeeId) return this.buy.checkedInByEmployeeId;
      return null;
    },
    buyStatus() {
      return capitalize(this.buy.openBuyStatus);
    },
    buyStartField() {
      switch (this.buy.openBuyStatus) {
        case OPEN_BUY_STATUSES.ENTERING:
          return this.buy.startEnteringAt;
        case OPEN_BUY_STATUSES.WAITING_TO_ENTER:
          return this.buy.stopSortingAt;
        case OPEN_BUY_STATUSES.SORTING:
          return this.buy.startedAt;
        default:
          return null;
      }
    },
    chipColor() {
      switch (this.buy.openBuyStatus) {
        case OPEN_BUY_STATUSES.ENTERING:
          return "green darken-2";
        case OPEN_BUY_STATUSES.WAITING_TO_ENTER:
          return "blue darken-2";
        case OPEN_BUY_STATUSES.SORTING:
          return "purple";
        default:
          return null;
      }
    },
  },
  created() {
    this.startInterval();
  },
  beforeDestroy() {
    this.stopInterval();
  },
  methods: {
    // Sets "now" every 30s. Used for time-based row background colors
    startInterval() {
      this.$options.interval = setInterval(
        () => (this.now = new Date()),
        30000
      );
    },
    stopInterval() {
      clearInterval(this.$options.interval);
    },
    onShowItem() {
      this.$emit("show", this.buy.id);
    },
  },
};
</script>

<template>
  <VxDataListItem
    v-bind="$attrs"
    :class="{
      ...dynamicClasses,
      'rai-data': true,
      'py-2': true,
    }"
    v-on="$listeners"
    @showItem="onShowItem"
  >
    <template #desktop>
      <td class="pl-8">
        <BuyNumber :trans-id="buy.transId" class="text-truncate" />
      </td>
      <td>
        <FlaggedName
          :customer="buy.customer"
          :darken="isError"
          class="buy-list-item-name font-weight-bold"
        />
        <span
          class="font-weight-bold pl-2 text--secondary"
          v-text="buy.containerNum"
        />
        -
        <span class="text--secondary" v-text="buy.containerDescription" />
        <span class="font-italic text--secondary" v-text="buy.containerSize" />
      </td>
      <td class="text--secondary sorter-cell d-flex justify-space-evenly pl-0">
        <VxChip v-if="hasSorter" :color="chipColor" outlined small>
          {{ buyStatus }}
        </VxChip>
        <StartSortingButton
          v-if="showSortingBtn"
          class="px-4"
          :buy-id="buy.id"
          :button-text="'Start'"
          text
          @click.stop
        />
        <RaiEmployeeAvatarList
          :custom-classes="{ 'px-3': true }"
          :employees="employeesToShow"
          :size="24"
        />
        <Stopwatch
          v-if="buyStartField !== null"
          class="rai-data"
          :start="buyStartField"
        />
      </td>
    </template>

    <template #mobile>
      <v-list-item-content
        class="item__content buy-number-cell-mobile py-0 align-self-start"
      >
        <BuyNumber :trans-id="buy.transId" hide-prefix class="text-truncate" />
      </v-list-item-content>
      <v-list-item-content class="item__content py-0 pl-2 align-self-start">
        <FlaggedName :customer="buy.customer" class="d-inline" />
        <v-list-item-subtitle>
          <span class="font-weight-bold" v-text="buy.containerNum" /> -
          <span v-text="buy.containerDescription" />
        </v-list-item-subtitle>
        <span>
          <VxChip v-if="hasSorter" :color="chipColor" outlined x-small>
            {{ buyStatus }}
          </VxChip>
        </span>
      </v-list-item-content>
      <v-list-item-action class="my-0 justify-start align-center">
        <v-list-item-action-text class="item__content">
          <Stopwatch
            v-if="buyStartField !== null"
            class="rai-data"
            :start="buy.checkedInAt"
          />
          <StartSortingButton
            v-if="showSortingBtn"
            :buy-id="buy.id"
            :button-text="'Start'"
            text
            @click.stop
          />
        </v-list-item-action-text>
        <v-list-item-avatar v-if="buy && buy.sorterId" class="ma-0" height="28">
          <Avatar :employee-id="mostRecentEmployee" size="28" />
        </v-list-item-avatar>
      </v-list-item-action>
    </template>
  </VxDataListItem>
</template>

<style scoped>
.buy-list-item-name {
  display: inline !important;
}

.sorter-cell {
  height: 36px !important;
}

.buy-number-cell-mobile {
  max-width: 40px;
}
</style>
