<script>
import BuyListItemOpen from "./BuyListItem/BuyListItemOpen";
import { VxDataList } from "@/core-ui";
import { VxDataListGroup } from "@/core-ui";

import { format, formatISO, isToday, isBefore, parseISO } from "date-fns";
import { map, chain, groupBy } from "lodash";

export default {
  name: "BuyListOpen",
  components: {
    BuyListItemOpen,
    VxDataList,
    VxDataListGroup,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    buysLoading: {
      type: [Boolean, Number],
      default: undefined,
    },
  },
  data: () => ({
    columnCssClasses: ["buy-number-cell", "name-container-cell", "sorter-cell"],
  }),
  computed: {
    groupedItems() {
      const grouped = groupBy(
        this.computedItems,
        (item) => item.estimatedPickupAtKey
      );
      const groupedMapped = map(grouped, (values, key) => {
        return { name: key, items: values };
      });

      const regroupedBuys = this.regroupBuys(groupedMapped);
      return (
        Object.keys(regroupedBuys)
          // Use date-fns isBefore to ensure proper time comparisons
          .sort(
            (a, b) =>
              (isBefore(parseISO(a), parseISO(b)) && -1) ||
              (isBefore(parseISO(b), parseISO(a)) && 1) ||
              0
          )
          .map((key) => {
            return {
              title: chain(regroupedBuys[key])
                .head()
                .get("estimatedPickupAtDisplay")
                .value(),
              items: regroupedBuys[key],
            };
          })
      );
    },
    // Adds an `estimatedPickupAtDisplay` and an `estimatedPickupAtKey` to each `item`
    // `estimatedPickupAtDisplay` is used for displaying the time slots as subheaders
    // `estimatedPickupAtKey` is used for grouping and sorting.
    computedItems() {
      return this.items
        .map((item) => ({
          ...item,
          estimatedPickupAtDisplay:
            (item.estimatedPickupAt &&
              format(
                parseISO(item.estimatedPickupAt),
                (isToday(parseISO(item.estimatedPickupAt)) && "h:mm aaa") ||
                  "h:mm aaa, MMM d"
              )) ||
            "No time quoted",
          estimatedPickupAtKey:
            (item.estimatedPickupAt &&
              formatISO(parseISO(item.estimatedPickupAt))) ||
            "",
        }))
        .sort(({ transId: a }, { transId: b }) => a - b);
    },
    sortBySlot() {
      return this.groupedItems.flatMap((buy) => buy.items);
    },
    buysToShowSortBtn() {
      return [this.sortBySlot.find((buy) => !buy.sorterId)];
    },
  },
  methods: {
    // Expects each `partitions` to be an object with `name` and `items` keys
    //
    // regroupedBuys: [{name: 'key', items: [{vals}]}] -> {key: items}
    regroupBuys(partitions) {
      return partitions.reduce((acc, b) => {
        acc[b.name] = b.items;
        return acc;
      }, {});
    },
  },
};
</script>

<template>
  <VxDataList
    class="buy-list-open py-0"
    :loading="buysLoading"
    :empty-data-text="'No open buys.'"
    :data-loading-text="'Loading open buys.'"
    dense
    is-list
  >
    <template #groups>
      <VxDataListGroup
        v-for="group of groupedItems"
        :key="group.title"
        :column-css-classes="columnCssClasses"
        :group-title="group.title"
      >
        <BuyListItemOpen
          v-for="item of group.items"
          :key="`open-${group.title}-${item.id}`"
          :buy="item"
          :buys-for-sorting="buysToShowSortBtn"
          @show="(buyId) => $emit('show', buyId)"
        />
      </VxDataListGroup>
    </template>
  </VxDataList>
</template>

<style lang="scss">
.buy-list-open {
  .buy-number-cell {
    width: 110px;
  }

  .name-container-cell {
    /* THIS one has auto width */
  }

  .sorter-cell {
    width: 300px;
    justify-content: end;
    align-items: center;
  }

  .group-title-mobile {
    height: fit-content;
  }
}
</style>
