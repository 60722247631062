<script>
import { RaiCompoundButton, RaiEmployeeSelectList } from "@/core-ui";
import {
  hasActiveEmployeeIdAccess,
  injectClockedInEmployees,
} from "@/mixins/employee";
import { hasSnackbarAccess } from "@/mixins/ui";
import START_BUY from "../graphql/StartBuy.gql";
export default {
  name: "StartSortingButton",
  components: { RaiCompoundButton, RaiEmployeeSelectList },
  mixins: [
    hasActiveEmployeeIdAccess,
    injectClockedInEmployees,
    hasSnackbarAccess,
  ],
  props: {
    buyId: {
      type: String,
    },
  },
  data() {
    return {
      menu: false,
      loading: false,
    };
  },
  methods: {
    handleStartClick() {
      this.startSorting();
    },
    handleSelectClick(selectedEmployee) {
      this.menu = false;
      this.startSorting(selectedEmployee.id);
    },
    async startSorting(employeeId = this.activeEmployeeId) {
      this.loading = true;
      try {
        await this.$apollo.mutate({
          mutation: START_BUY,
          variables: {
            id: this.buyId,
            employeeId,
          },
        });
      } catch (error) {
        this.showSnackbar({
          text: `An error occurred while setting the buy sorter: ${error}`,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<template>
  <RaiCompoundButton
    v-model="menu"
    :loading="loading"
    v-bind="$attrs"
    @click.stop="handleStartClick"
  >
    <RaiEmployeeSelectList
      :employees="clockedInEmployees"
      :hide-search="false"
      :clear-search="menu"
      @input="handleSelectClick"
    />
  </RaiCompoundButton>
</template>
